import { __decorate } from "tslib";
import { Options, Vue } from "vue-class-component";
import { ref } from "vue";
import navlist from "@/components/navlist.vue";
import aboutItem from '@/components/aboutItem.vue';
let about = class about extends Vue {
    setup() {
        const month = ref("9");
        return {
            month,
        };
    }
};
about = __decorate([
    Options({
        components: {
            navlist, aboutItem
        },
    })
], about);
export default about;
