// import { Options, Vue } from 'vue-class-component';
import { defineComponent, ref } from "vue";
export default defineComponent({
    setup() {
        let NavList = ref([
            {
                url: "https://today-plus.oss-cn-beijing.aliyuncs.com/icon_apple.png",
                id: 1,
                name: "ios",
            },
            {
                url: "https://today-plus.oss-cn-beijing.aliyuncs.com/icon_android.png",
                id: 2,
                name: "ANDROID",
            },
            {
                url: "https://today-plus.oss-cn-beijing.aliyuncs.com/lianjie.png",
                id: 3,
                name: "小程序",
            },
        ]);
        let mouseIndex = ref(100);
        let timestr = ref(false);
        setTimeout(() => {
            timestr.value = true;
        }, 500);
        // 鼠标进入
        const enter = (e) => {
            mouseIndex.value = e;
            console.log("进去", mouseIndex.value);
        };
        const outer = (e) => {
            console.log("移除", e);
            mouseIndex.value = 100;
        };
        return {
            NavList,
            enter,
            outer,
            mouseIndex,
            timestr,
        };
    },
});
