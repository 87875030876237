import { defineComponent, ref, reactive, toRefs } from "vue";
import navlist from "@/components/navlist.vue";
import funcItemA from "@/components/funcItemA.vue";
import funcItemB from "@/components/funcItemB.vue";
import funcItemC from "@/components/funcItemC.vue";
export default defineComponent({
    components: {
        navlist,
        funcItemA, funcItemB, funcItemC
    },
    setup() {
        let compontentList = reactive({
            List: [
                {
                    name: "funcItemA",
                    id: 1
                },
                {
                    name: "funcItemB",
                },
                {
                    name: "funcItemC",
                },
            ],
        });
        const change = (value) => {
            console.log('我的数据', value);
        };
        const month = ref("9");
        month.value = 9; // OK
        return {
            month,
            change,
            ...toRefs(compontentList),
        };
    },
});
